import dayjs from 'dayjs'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { useContext } from 'react'
import styled, { ThemeContext } from 'styled-components'

import DesignSystemLinkText from 'design-system/Links/LinkText'

import Anchor from 'components/GlobalStyles/Anchor'
import HorizontalRule from 'components/GlobalStyles/Hr'
import EqualOpportunityHousingIcon from 'components/SVG/compound/EqualOpportunityHousing'
import FacebookIcon from 'components/SVG/social/FacebookCircle'
import InstagramIcon from 'components/SVG/social/InstagramCircle'
import TwitterIcon from 'components/SVG/social/TwitterCircle'
import YouTubeIcon from 'components/SVG/social/YouTubeCircle'
import Spacer from 'components/Spacer'

import { EventHandlerContext } from 'services/contexts'

import CampaignData from 'data/MarketingData/CampaignData'
import LicensingData from 'data/MarketingData/LicensingData'

const DisabledOverlay = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  cursor: wait;
`

const FooterContainer = styled.div`
  width: 100%;
  max-width: 320px;
  @media (min-width: ${props => props.theme.metrics.tablet}px) {
    max-width: ${props => props.theme.metrics.contentMaxWidth}px;
  }
`

const FooterWrapper = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 ${props => props.theme.metrics.gutterPhone}px;
  color: white;
  background-color: ${props => props.theme.colors.grey27};
  @media (min-width: ${props => props.theme.metrics.tablet}px) {
    padding: 0 50px;
  }
`

interface IGridProps {
  noColumns?: boolean
  rowGap?: number
}
const GridContainer = styled.div<IGridProps>`
  display: grid;
  grid-template-columns: 1fr 1fr;
  ${props => props.noColumns && 'grid-template-columns: 1fr;'}
  grid-column-gap: 30px;
  grid-row-gap: ${props => props?.rowGap ?? 36}px;
  @media (min-width: ${props => props.theme.metrics.tablet}px) {
    grid-template-columns: repeat(3, 1fr);
    ${props => props.noColumns && 'grid-template-columns: 1fr;'}
  }
  @media (min-width: ${props => props.theme.metrics.desktop}px) {
    grid-template-columns: repeat(5, 1fr);
    ${props => props.noColumns && 'grid-template-columns: 1fr;'}
  }
`

interface IGridItemProps {
  gridRow?: string
  gridRowTablet?: string
  gridRowDesktop?: string
  gridColumn?: string
  gridColumnTablet?: string
  gridColumnDesktop?: string
}

const GridItem = styled.div<IGridItemProps>`
  grid-column: ${props => (props.gridColumn ? props.gridColumn : 'auto')};
  grid-row: ${props => (props.gridRow ? props.gridRow : 'auto')};
  @media (min-width: ${props => props.theme.metrics.tablet}px) {
    grid-column: ${props => (props.gridColumnTablet ? props.gridColumnTablet : 'auto')};
    grid-row: ${props => (props.gridRowTablet ? props.gridRowTablet : 'auto')};
  }
  @media (min-width: ${props => props.theme.metrics.desktop}px) {
    grid-column: ${props => (props.gridColumnDesktop ? props.gridColumnDesktop : 'auto')};
    grid-row: ${props => (props.gridRowDesktop ? props.gridRowDesktop : 'auto')};
  }
`

interface ICenter {
  center?: boolean
}

const Section = styled.div<ICenter>`
  width: 100%;
  ${props =>
    props.center
    && `
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  `}
`

const CopyrightContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @media (min-width: ${props => props.theme.metrics.desktop}px) {
    flex-direction: row;
  }
`

const StyledAnchor = styled(Anchor)`
  text-decoration: underline;
  @media (min-width: ${props => props.theme.metrics.tablet}px) {
    flex-direction: row;
  }
`

const VerticalRule = styled.div`
  background-color: none;
  height: 24px;
  @media (min-width: ${props => props.theme.metrics.desktop}px) {
    display: block;
    width: 1px;
    height: 100px;
    margin: 0 30px;
    background-color: ${props => props.theme.colors.transparentWhiteAlpha20};
  }
`

const Logo = styled.img`
  width: 200px;
`

const SocialLinkContainer = styled.div`
  display: flex;
  margin-top: -5px;
`

const EmphasizedText = styled.span`
  font-weight: 800;
  font-size: 18px;
  text-align: center;
  color: ${props => props.theme.colors.white};
`

interface IHeaderTextProps {
  color?: string
}
const HeaderText = styled.div<IHeaderTextProps>`
  text-transform: uppercase;
  font-style: normal;
  font-weight: 800;
  font-size: 14px;
  white-space: nowrap;
  color: ${props => (props.color ? props.color : props.theme.colors.blue)};
  display: block;
  width: 100%;
  margin: 10px 0;
  @media (min-width: ${props => props.theme.metrics.desktop}px) {
    margin: 0px;
  }
`

interface ILinkTextProps {
  color?: string
}
const LinkText = styled(Anchor)<ILinkTextProps>`
  font-weight: 300;
  font-size: 14px;
  word-break: none;
  white-space: wrap;
  color: ${props => (props.color ? props.color : props.theme.colors.white)};
  cursor: pointer;
`

interface IColorProp {
  color?: string
}
const LegalText = styled.span<IColorProp>`
  font-size: 12px;
  text-align: center;
  color: ${props => (props.color ? props.color : props.theme.colors.white)};
  padding: 0 5px;
`

const HomeToursAppTextLink = styled(Anchor)`
  font-style: normal;
  font-weight: 900;
  font-size: 14px;
  line-height: 18px;
  color: ${props => props.theme.colors.white};
  cursor: pointer;
`

const HomeToursAppIconsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 100%;
  @media (min-width: ${props => props.theme.metrics.tablet}px) {
    flex-direction: row;
  }
`

const TrecNoticeContainer = styled.div`
  width: 100%;
  text-align: center;
`

interface ISizeProps {
  width?: string
}
const Image = styled.img<ISizeProps>`
  width: ${props => (props.width && `${props.width}px`) || '100%'};
  height: auto;
`

const footerText = `© ${dayjs().year()} HomeLister, Inc. All rights reserved. HomeLister Inc is a Licensed Brokerage in CA #01983286`

const Footer = () => {
  const theme = useContext(ThemeContext)

  const router = useRouter()
  const isInListingFlow = router.pathname.split('/').includes('create')
  const targetAttr = isInListingFlow ? '_blank' : '_self'
  const campaignRoute = router.asPath.split('c/').pop() || ''
  const stateLicensing = LicensingData[campaignRoute]

  // disable links when a debounced input hasn't made its update API call yet
  const { debouncing } = useContext(EventHandlerContext)

  const removeColumnLinks = isInListingFlow
  const removeDownloadLinks = isInListingFlow

  return (
    <FooterWrapper>
      {debouncing && <DisabledOverlay />}
      <FooterContainer>
        <Spacer size={45} />
        {!removeColumnLinks && (
          <>
            <GridContainer>
              <Section>
                <HeaderText>About</HeaderText>
                <Spacer size={14} />
                <div data-testid="whyHomelister">
                  <Link
                    href="/why-homelister"
                    prefetch={false}
                    passHref
                  >
                    <LinkText target={targetAttr}>Why HomeLister</LinkText>
                  </Link>
                </div>
                <Spacer size={14} />
                <div data-testid="howItWorks">
                  <Link
                    href="/how-it-works"
                    prefetch={false}
                    passHref
                  >
                    <LinkText target={targetAttr}>Sell</LinkText>
                  </Link>
                </div>
                <Spacer size={14} />
                <div data-testid="pricing">
                  <Link
                    href="/pricing"
                    prefetch={false}
                    passHref
                  >
                    <LinkText target={targetAttr}>Pricing</LinkText>
                  </Link>
                </div>
                <Spacer size={14} />
                <div data-testid="homesForSale">
                  <Link
                    href="/listings/homes-for-sale"
                    prefetch={false}
                    passHref
                  >
                    <LinkText target={targetAttr}>Homes for Sale</LinkText>
                  </Link>
                </div>
              </Section>
              <Section>
                <HeaderText>Support</HeaderText>
                <Spacer size={14} />
                <div data-testid="faq">
                  <Link
                    href="/faq"
                    prefetch={false}
                    passHref
                  >
                    <LinkText target={targetAttr}>FAQ</LinkText>
                  </Link>
                </div>
                <Spacer size={14} />
                <div data-testid="contactUs">
                  <Link
                    href="/contact-us"
                    prefetch={false}
                    passHref
                  >
                    <LinkText target={targetAttr}>Contact Us</LinkText>
                  </Link>
                </div>
              </Section>
              <Section>
                <Spacer size={14} />
              </Section>
              <Section>
                <HeaderText>Legal</HeaderText>
                <Spacer size={14} />
                <div data-testid="termsOfUse">
                  <Link
                    href="/terms-of-use"
                    prefetch={false}
                    passHref
                  >
                    <LinkText target={targetAttr}>Terms of Use</LinkText>
                  </Link>
                </div>
                <Spacer size={14} />
                <div data-testid="privacy">
                  <Link
                    href="/privacy"
                    prefetch={false}
                    passHref
                  >
                    <LinkText target={targetAttr}>Privacy Policy</LinkText>
                  </Link>
                </div>
                <Spacer size={14} />
                <div data-testid="licensing">
                  <Link
                    href="/licensing"
                    prefetch={false}
                    passHref
                  >
                    <LinkText target={targetAttr}>Licensing</LinkText>
                  </Link>
                </div>
              </Section>
              <Section>
                <HeaderText>Follow Us</HeaderText>
                <Spacer size={14} />
                <SocialLinkContainer>
                  <Anchor
                    data-testid="facebookLogo"
                    href="https://www.facebook.com/HomeLister"
                    target="_blank"
                    aria-label="Open Homlister Facebook page"
                  >
                    <FacebookIcon
                      width="42"
                      height="42"
                      fill={theme.colors.blue11}
                      alt="Facebook Logo in a circle"
                    />
                  </Anchor>
                  <Spacer size={5} />
                  <Anchor
                    data-testid="twitterLogo"
                    href="https://twitter.com/homelisterapp"
                    target="_blank"
                  >
                    <TwitterIcon
                      width="42"
                      height="42"
                      fill={theme.colors.blue11}
                      alt="Twitter Logo in a circle"
                    />
                  </Anchor>
                  <Spacer size={5} />
                  <Anchor
                    data-testid="instagramLogo"
                    href="https://www.instagram.com/homelister"
                    target="_blank"
                    aria-label="Open Homlister Instagram page"
                  >
                    <InstagramIcon
                      width="42"
                      height="42"
                      fill={theme.colors.blue11}
                      alt="Instagram Logo in a circle"
                    />
                  </Anchor>
                  <Spacer size={5} />
                  <Anchor
                    data-testid="youtubeLogo"
                    href="https://www.youtube.com/channel/UCImizowwaFJK-Cn4u2Dvniw"
                    target="_blank"
                    aria-label="Open Homlister Youtube channel"
                  >
                    <YouTubeIcon
                      width="42"
                      height="42"
                      fill={theme.colors.blue11}
                      alt="Youtube Logo in a circle"
                    />
                  </Anchor>
                </SocialLinkContainer>
              </Section>
            </GridContainer>
            <Spacer size={36} />
            <HorizontalRule
              height={1}
              color="transparentWhiteAlpha20"
            />
            <Spacer size={36} />
            <GridContainer rowGap={20}>
              <GridItem
                gridColumn="1 / span 2"
                gridColumnTablet="1 / span 3"
                gridRowTablet="auto"
                gridRowDesktop="1 / 4"
              >
                <HeaderText color={theme.colors.blue11}>Learn More:</HeaderText>
              </GridItem>
              {Object.keys(CampaignData)
                .filter(key => key !== 'home')
                .map((key, index) => (
                  <GridItem
                    key={key}
                    gridColumn="1 / span 2"
                    gridColumnTablet="auto / span 1"
                    gridColumnDesktop={`${index % 2 === 0 ? 2 : 4} / span 2`}
                  >
                    <div data-testid={CampaignData[key].label}>
                      <Link
                        href={`/c/${key}`}
                        prefetch={false}
                        passHref
                      >
                        <LinkText
                          color="grey21"
                          target={targetAttr}
                        >
                          {CampaignData[key].label}
                        </LinkText>
                      </Link>
                    </div>
                  </GridItem>
                ))}
            </GridContainer>
            <Spacer size={36} />
          </>
        )}
        {!removeDownloadLinks && (
          <>
            <Section>
              <Spacer size={36} />
              <HorizontalRule
                height={1}
                color="transparentWhiteAlpha20"
              />
              <Spacer size={36} />
              <Section center>
                <DesignSystemLinkText
                  href="/partner-offers"
                  text="Partner Offers for Homeowners"
                />
              </Section>
              <Spacer size={36} />
            </Section>
            <Section center>
              <Link
                href="/virtual-home-tours"
                passHref
              >
                <HomeToursAppTextLink target={targetAttr}>
                  Download HomeLister Live Tours App
                </HomeToursAppTextLink>
              </Link>
              <Spacer size={12} />
              <HomeToursAppIconsContainer>
                <Anchor
                  href="https://apps.apple.com/us/app/homelister-live-tours/id1515090384"
                  target={targetAttr}
                >
                  <Image
                    src={`${process.env.CDN_BASE_URL}/q_auto/amazon/assets/appstore-button-light.png`}
                    width="200"
                    height="65"
                    alt="Apple Logo and the text 'Download on the App Store'"
                  />
                </Anchor>
                <Spacer size={12} />
                <Anchor
                  href="https://play.google.com/store/apps/details?id=com.homelister&hl=en_US"
                  target={targetAttr}
                >
                  <Image
                    src={`${process.env.CDN_BASE_URL}/q_auto/amazon/assets/google-play-button-light.png`}
                    width="200"
                    height="65"
                    alt="Google Logo and the text 'Get It on Google Play'"
                  />
                </Anchor>
              </HomeToursAppIconsContainer>
            </Section>
            <Spacer size={36} />
            <HorizontalRule
              height={1}
              color="transparentWhiteAlpha20"
              hideOnDesktop
            />
            <Spacer
              size={36}
              hideDesktop
            />
          </>
        )}
        <CopyrightContainer>
          <Image
            src={`${process.env.CDN_BASE_URL}/q_auto/amazon/assets/zillow-rating-white-text.png`}
            width="150"
            height="125"
            alt="The Zillow logo with five yellow stars above it and the text 'Rated 4.8/5' below"
          />
          <VerticalRule />
          <Image
            src={`${process.env.CDN_BASE_URL}/q_auto/amazon/assets/Best_Place_To_Work_LA.png`}
            width="94"
            height="125"
            alt="BuiltIn 2022 Best Places to Work Los Angeles"
          />
          <VerticalRule />
          <EqualOpportunityHousingIcon
            width="72"
            height="72"
            fill={theme.colors.white}
          />
        </CopyrightContainer>
        <Spacer
          size={50}
          tabletSize={24}
          responsiveSize={24}
        />
        <Section center>
          <Image
            src={`${process.env.CDN_BASE_URL}/q_auto/amazon/assets/trust-badge-aes-256-bit-ssl.png`}
            width="150px"
            height="3ppx"
            alt="SSL Trust Badge"
          />
        </Section>
        <Spacer size={36} />
        <HorizontalRule
          height={1}
          color="transparentWhiteAlpha20"
          hideOnDesktop
        />
        <Spacer
          size={36}
          hideDesktop
        />
        <TrecNoticeContainer>
          <LegalText color={theme.colors.yellow4}>
            <StyledAnchor
              href={`${process.env.S3_BASE_URL}/TX+IABS.pdf`}
              target="_blank"
            >
              TREC: Info about Brokerage Services
            </StyledAnchor>
          </LegalText>
          <LegalText color={theme.colors.yellow4}>
            <StyledAnchor
              href="https://www.trec.texas.gov/sites/default/files/pdf-forms/CN%201-4-1_1.pdf"
              target="_blank"
            >
              TREC Consumer Protection Notice
            </StyledAnchor>
          </LegalText>
          <LegalText color={theme.colors.yellow4}>
            <StyledAnchor
              href={`${process.env.S3_BASE_URL}/NY-FairHousingNotice-(2020).pdf`}
              target="_blank"
            >
              New York State Fair Housing Notice
            </StyledAnchor>
          </LegalText>
        </TrecNoticeContainer>
        <Spacer size={20} />
        <Section center>
          <LegalText>
            Apple and the Apple Logo are trademarks of Apple Inc. Google Play and Google Play logo
            are trademarks of Google LLC.
          </LegalText>
        </Section>
        <Spacer size={12} />
        <Section center>
          <LegalText>{footerText}</LegalText>
          {stateLicensing && <LegalText>{stateLicensing.licensing}</LegalText>}
          {stateLicensing?.name === 'TEXAS' && (
            <Anchor
              href={`${process.env.S3_BASE_URL}/TX+IABS.pdf`}
              target="_blank"
            >
              <LegalText>TREC: Info about Brokerage Services</LegalText>
            </Anchor>
          )}
        </Section>
        <Spacer size={36} />
        <Section center>
          <Logo
            src="/static/logos/homelister_logo.png"
            alt="HomeLister Logo"
            width="200"
            height="45"
          />
          <Spacer size={10} />
          <EmphasizedText>(855) 400 - 8566</EmphasizedText>
        </Section>
        <Spacer
          size={50}
          tabletSize={70}
        />
      </FooterContainer>
    </FooterWrapper>
  )
}

export default Footer
