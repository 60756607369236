export enum ButtonType {
  button = 'button',
  submit = 'submit',
  reset = 'reset',
}

// Breadcrumb name type
export enum BreadcrumbEnum {
  Home = 'Home',
  Dashboard = 'My Dashboard',
  HomesForSale = 'Homes For Sale',
  RecentSales = 'Recent Sales',
  HowItWorks = 'Sell',
  Pricing = 'Pricing',
  FAQ = 'FAQ',
  ViewListing = 'View Listing',
}

export enum HomeTypeEnum {
  condo = 'Condo',
  coop = 'Coop',
  detached = 'House',
  duplex = 'Duplex',
  land = 'Land',
  townhouse = 'Townhouse',
  other = 'Other',
}

export enum LocalStorageKey {
  guidedTourMode = 'guidedTourMode',
  tempEstimatedHomeValue = 'tempEstimatedHomeValue',
  listingEstimatedHomeValue = 'listingEstimatedHomeValue',
}

export enum OwnerType {
  Company = 'Company',
  Individuals = 'Individuals',
  Trust = 'Trust',
}

export enum PackageType {
  basic = 'Basic',
  premium = 'Premium',
  platinum = 'Platinum',
  elite = 'Elite',
}

export enum PackageCardMode {
  Best = 'best',
  ComingSoon = 'comingSoon',
}

// specifies required static fields (non-dynamic fields) on each substeps and create its field ids.
export enum StaticFieldId {
  // homeDetails/basic-home-details
  PropertyType = 'propertyType',
  // photos
  PhotosUploaded = 'photosUploaded',
  CanUsePhotos = 'canUsePhotos',
  // showings
  PrimaryContactName = 'contactInfo.name',
  PrimaryContactPhone = 'contactInfo.phone',
  PrimaryContactEmail = 'contactInfo.email',
  // offer-pricing
  HomeListPrice = 'homeListPrice',
  ListPriceIncludes = 'listPriceIncludes',
  ListPriceExcludes = 'listPriceExcludes',
  // mls-rules
  MlsRulesAgreed = 'mlsRulesAgreed',
}

export enum SidebarPosition {
  top = 'top',
  bottom = 'bottom',
}

export enum ContractStatus {
  optionPeriod = 'Option Period',
  offerAccepted = 'Offer Accepted, Contract Not Yet Signed',
  attorneyReview = 'Attorney Review',
  underContract = 'Under Contract (Has contingencies)',
  pending = 'Pending (No contingencies)',
}

export enum BuyerFinancingType {
  conventional = 'Conventional',
  fha = 'FHA',
  usda = 'USDA',
  va = 'VA',
  cash = 'Cash',
  owner = 'Owner Financing',
  other = 'Other',
}

export enum ServiceType {
  Public = 'public',
  YardSign = 'yardSign',
  Photography = 'photography',
  TransactionManagement = 'transactionManagement',
}

export enum ListingStatus {
  draft = 'Draft',
  published = 'Published',
  needsRevision = 'Needs Revision',
  listingDetailsUpdated = 'Listing Details Updated',
  remove = 'Remove',
  inContract = 'In Contract',
  sold = 'Sold',
  inProgress = 'In Progress',
  brokerReview = 'Broker Review',
  sellerInfoRequested = 'Seller Info Requested',
  readyForSubmission = 'Ready For Submission',
  cancelled = 'Cancelled',
  referred = 'Referred',
  closedNotPaid = 'Closed Not Paid',
  amendmentSent = 'Amendment Sent',
  amendmentNeeded = 'Amendment Needed',
  cancellationSigned = 'Cancellation Signed',
  leased = 'Leased',
  expired = 'Expired',
  offerAcceptedNYIC = 'Offer Accepted NYIC',
  tempHold = 'Temp Hold',
  offerReceived = 'Offer Received',
  needsInput = 'Needs Input',
  readyForPublish = 'Ready For Publish',
  attorneyReview = 'Attorney Review',
  contractUpdated = 'Contract Updated',
  escrowClosed = 'Escrow Closed',
  newEscrow = 'New Escrow',
  initialReviewFailed = 'Initial Review Failed',
}

export enum RoomLocation {
  Level = 'Level',
  Basement = 'Basement',
}

export enum VwoGoalIdentifier {
  signup = 'goal_signup_fe',
  basic = 'goal.purchase_service_package.basic.fe',
  premium = 'goal.purchase_service_package.premium.fe',
  platinum = 'goal.purchase_service_package.platinum.fe',
  addOnService = 'goal.service_add',
}

export enum AverageHomeValueType {
  stateMedian = 'stateMedian',
}

export enum SocialMedia {
  facebook = 'facebook',
  google = 'google',
  apple = 'apple',
}

export enum JustifyContent {
  flexStart = 'flex-start',
  flexEnd = 'flex-end',
  center = 'center',
  spaceBetween = 'space-between',
  spaceAround = 'space-around',
  spaceEvenly = 'space-evenly',
}

export enum FeatureType {
  service = 'service',
  help = 'help',
  support = 'support',
}

export enum MapSearchForSaleType {
  forSale = 'For Sale',
  inContract = 'In Contract',
  sold = 'Sold',
}

export enum SizeType {
  small = 'small',
  medium = 'medium',
  large = 'large',
  extraLarge = 'extraLarge',
  responsive = 'responsive',
}
