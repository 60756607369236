/* eslint-disable jsx-a11y/anchor-is-valid */
import Link from 'next/link'
import { useRouter } from 'next/router'
import { useContext } from 'react'
import styled from 'styled-components'

import Anchor from 'components/GlobalStyles/Anchor'
import Spacer from 'components/Spacer'

import { EventHandlerContext } from 'services/contexts'

import Help from './Help'
import { ITransparentProps } from './types'

const NavLinkContainer = styled.div<ITransparentProps>`
  flex-direction: column;
  display: none;
  a {
    color: ${props =>
    props.transparent ? props.theme.colors.white : props.theme.colors.neutral900};
  }
  @media (min-width: ${props => props.theme.metrics.desktop}px) {
    display: flex;
    flex-direction: row;
    align-items: center;
    text-align: center;
    max-width: 750px;
  }
`

interface IStyledLinkProps {
  debouncing?: boolean
}
const StyledLink = styled(Anchor)<IStyledLinkProps>`
  display: flex;
  align-items: center;
  font-style: normal;
  font-weight: 800;
  font-size: 14px;
  line-height: 16px;
  text-transform: uppercase;
  text-align: center;
  color: ${props => props.theme.colors.neutral900};
  margin-top: 2px; /* to accomodate border */
  border-bottom: 2px solid ${props => props.theme.colors.transparent};
  transition: color 150ms ease, border-bottom 150ms ease;
  ${props => props.debouncing && 'cursor: wait;'}

  &:hover {
    color: ${props => props.theme.colors.teal500};
    border-bottom: 2px solid ${props => props.theme.colors.teal500};
  }
`

const StyledText = styled.div`
  display: flex;
  align-items: center;
  font-weight: 800;
  font-size: 14px;
  line-height: 16px;
  text-transform: uppercase;
  text-align: center;
  color: ${props => props.theme.colors.neutral900};
  margin-top: 2px; /* to accomodate border */
  border-bottom: 2px solid ${props => props.theme.colors.transparent};
  transition: color 250ms ease, border-bottom 250ms ease;
  cursor: wait;
`

const LISTING_DASHBOARD_PATH = '/'
const FAQ_PATH = '/faq'
const ACCOUNT_PATH = '/account'
const HOW_IT_WORKS_PATH = '/how-it-works'
const PRICING_PATH = '/pricing'
const BUY_PATH = '/buy'
const PRO_PATH = '/pro'
const HOMES_FOR_SALE_PATH = '/listings/homes-for-sale'

const LISTING_DASHBOARD_LABEL = 'Listing Dashboard'
const FAQ_LABEL = 'FAQ'
const ACCOUNT_LABEL = 'Account'
const HOW_IT_WORKS_LABEL = 'Sell'
const PRICING_LABEL = 'Pricing'
const BUY_LABEL = 'Buy'
const PRO_LABEL = 'Pro'
const HOMES_FOR_SALE_LABEL = 'Homes For Sale'

const isAlreadyOnPage = (pathname: string, innerText: string) => {
  switch (pathname) {
    case LISTING_DASHBOARD_PATH:
      if (innerText === LISTING_DASHBOARD_LABEL.toUpperCase()) return true

      break
    case FAQ_PATH:
      if (innerText === FAQ_LABEL.toUpperCase()) return true

      break
    case PRICING_PATH:
      if (innerText === PRICING_LABEL.toUpperCase()) return true

      break
    case ACCOUNT_PATH:
      if (innerText === ACCOUNT_LABEL.toUpperCase()) return true

      break
    case HOMES_FOR_SALE_PATH:
      if (innerText === HOMES_FOR_SALE_LABEL.toUpperCase()) return true

      break
    default:
      return false
  }
}

interface INavLinksProps {
  transparent?: boolean
  closeModal?: () => void
}

const NavLinks = ({ transparent, closeModal }: INavLinksProps) => {
  const router = useRouter()

  // disable links when a debounced input hasn't made its update API call yet
  const { debouncing } = useContext(EventHandlerContext)

  const handleClick = (event: React.MouseEvent) => {
    // close modal if user is already on the page whose link they're clicking
    // take advantage of event bubbling to get inner text of the clicked link
    const target = event.target as HTMLElement
    const innerText = target?.innerText
    if (!closeModal) return

    if (isAlreadyOnPage(router.pathname, innerText)) closeModal()
  }

  return (
    <NavLinkContainer
      onClick={handleClick}
      transparent={transparent}
    >
      <Spacer size={20} />
      {debouncing
        ? (
          <StyledText>{HOW_IT_WORKS_LABEL}</StyledText>
        )
        : (
          <Link
            href={HOW_IT_WORKS_PATH}
            prefetch={false}
            passHref
          >
            <StyledLink>{HOW_IT_WORKS_LABEL}</StyledLink>
          </Link>
        )}
      <Spacer size={32} />
      {debouncing
        ? (
          <StyledText>{PRICING_LABEL}</StyledText>
        )
        : (
          <Link
            href={PRICING_PATH}
            prefetch={false}
            passHref
          >
            <StyledLink>{PRICING_LABEL}</StyledLink>
          </Link>
        )}
      <Spacer size={32} />
      {debouncing
        ? (
          <StyledText>{BUY_LABEL}</StyledText>
        )
        : (
          <Link
            href={BUY_PATH}
            prefetch={false}
            passHref
          >
            <StyledLink>{BUY_LABEL}</StyledLink>
          </Link>
        )}
      <Spacer size={32} />
      {debouncing
        ? (
          <StyledText>{PRO_LABEL}</StyledText>
        )
        : (
          <Link
            href={PRO_PATH}
            prefetch={false}
            passHref
          >
            <StyledLink>{PRO_LABEL}</StyledLink>
          </Link>
        )}
      <Spacer size={32} />
      {debouncing
        ? (
          <StyledText>{HOMES_FOR_SALE_LABEL}</StyledText>
        )
        : (
          <Link
            href={HOMES_FOR_SALE_PATH}
            prefetch={false}
            passHref
          >
            <StyledLink>{HOMES_FOR_SALE_LABEL}</StyledLink>
          </Link>
        )}
      <Spacer size={32} />
      <Help
        transparent={transparent}
        inDropDown
      />
      <Spacer size={20} />
    </NavLinkContainer>
  )
}

export default NavLinks
