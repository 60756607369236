import { useRouter } from 'next/router'
import React, { FunctionComponent, ReactNode, useEffect, useRef, useState } from 'react'
import styled from 'styled-components'

interface PopoverBodyProps {
  backgroundColor?: string
  marginRight?: number
  padding?: number
  width?: number
  visible: boolean
}
const PopoverBody = styled.span<PopoverBodyProps>`
  visibility: ${props => (props.visible ? 'visible' : 'hidden')};
  opacity: ${props => (props.visible ? 1 : 0)};
  position: absolute;
  top: 130%;
  right: 0;
  ${({ width }) => width && `width: ${width}px;`}
  margin-right: ${({ marginRight }) => marginRight || 0}px;
  padding: ${({ padding }) => padding || 0}px;
  border-radius: 12px;
  background-color: ${({ theme, backgroundColor }) =>
    backgroundColor ? theme.colors[backgroundColor] : theme.colors.white};
  filter: drop-shadow(0px 0px 48px rgba(112, 112, 112, 0.25));
  z-index: 100;
  transition: opacity 0.1s ease-out;
  border-top-left-radius: 12px;

  &:before {
    content: ' ';
    position: absolute;
    background-color: inherit;
    width: 35px;
    height: 35px;
    border-top-right-radius: 10px;
    transform: rotate(-60deg) skewX(-30deg) scale(1, 0.866);
    right: 30px;
    top: -10px;
    z-index: -1;
  }
`

interface IClickableTextProps {
  textColor?: string
}
const ClickableText = styled.div<IClickableTextProps>`
  position: relative;
  cursor: pointer;
`

interface IPopoverProps {
  backgroundColor?: string
  marginRight?: number
  padding?: number
  text?: string
  textColor?: string
  width?: number
  renderFn?(): ReactNode
}

const Popover: FunctionComponent<IPopoverProps> = ({
  backgroundColor,
  children,
  marginRight,
  padding,
  text,
  textColor,
  width,
  renderFn,
}) => {
  const containerRef = useRef<HTMLDivElement>(null)
  const router = useRouter()
  const [visible, setVisible] = useState<boolean>(false)

  const toggleVisible = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation()
    setVisible(prev => !prev)
  }

  const handleOutsideClick = (event: MouseEvent) => {
    const target = event.target as Node
    if (containerRef.current && containerRef.current.contains(target)) return

    setVisible(false)
  }

  useEffect(() => {
    if (visible) {
      document.addEventListener('click', handleOutsideClick)
    } else {
      document.removeEventListener('click', handleOutsideClick)
    }

    return () => {
      document.removeEventListener('click', handleOutsideClick)
    }
  }, [visible])

  useEffect(() => {
    setVisible(false)
  }, [router.pathname])

  return (
    <ClickableText
      ref={containerRef}
      textColor={textColor}
      onClick={toggleVisible}
    >
      {!!text && text}
      {!!renderFn && renderFn()}
      <PopoverBody
        backgroundColor={backgroundColor}
        padding={padding}
        marginRight={marginRight}
        width={width}
        visible={visible}
      >
        {children}
      </PopoverBody>
    </ClickableText>
  )
}

export default Popover
