import Link from 'next/link'
import { useRouter } from 'next/router'
import { FunctionComponent, ReactNode, useContext, useEffect } from 'react'
import styled, { ThemeContext } from 'styled-components'

import Anchor from 'components/GlobalStyles/Anchor'
import HorizontalRule from 'components/GlobalStyles/Hr'
import RoundedButton from 'components/RoundedButton'
import DashboardIcon from 'components/SVG/DashboardOutlined'
import EditIcon from 'components/SVG/Edit'
import PlusIcon from 'components/SVG/Plus'
import { DownFilledArrow } from 'components/SVG/filledArrows'
import Spacer from 'components/Spacer'

import { useUserListings } from 'services/swr/listings/useUserListings'
import { useListing } from 'services/swr/useListing'

import { getEditLinkByMostRecentlyUpdated } from 'utils/routing'

import * as E from 'types/enums'

import DropDown from './DropDown'
import { ContentContainer, IconContainer, ItemRow, TitleText } from './Shared'

const WaitContainer = styled.div`
  & > ${ItemRow} {
    cursor: wait;
  }
`
interface IProps {
  disabled?: boolean
  inDropDown?: boolean
  toggleModal?: () => void
}

const MyListing: FunctionComponent<IProps> = ({ disabled, inDropDown, toggleModal }) => {
  const theme = useContext(ThemeContext)
  const router = useRouter()

  // we don't want to grab the listing if on a public listing page
  // since it doesn't matter for determining the user's most recently edited listing
  const isOnPublicListingPage = !router?.pathname || router.pathname === '/listing/[id]'

  // Edit listing goes to the most recently edited draft listing
  const { listing } = useListing({
    noFetch: isOnPublicListingPage,
    withToken: true,
  })

  const { listings, mutate } = useUserListings()
  const { href, as, subStep } = getEditLinkByMostRecentlyUpdated(listings)

  useEffect(() => {
    // we need to refetch `listings` whenever any listing is updated,
    // otherwise the `updatedAt` date will be stale and we won't show most recently edited listing
    const foundListing = listings?.find(userListing => userListing._id === listing?._id)
    if (foundListing?.updatedAt !== listing?.updatedAt) mutate()
  }, [listing])

  // @FIXME - a bit complicated to fix due to the EditLink ternary below
  // eslint-disable-next-line react/no-unstable-nested-components
  const LiveLink = ({ children }: { children: ReactNode }) => (
    <Link
      href={href}
      as={as}
      prefetch={false}
      passHref
    >
      {children}
    </Link>
  )

  // @IMPORTANT: The Edit Listing LiveLink can't go to the same page the user is currently on, if the
  // URL is dynamic (e.g. /listing/[id]/create/[subStep]). This will cause a page crash.
  const willTriggerPageCrash = router.query.subStep === subStep
  const EditLink = listings && listings.length && !willTriggerPageCrash ? LiveLink : WaitContainer

  const renderDivider = () =>
    inDropDown
      ? (
        <>
          <Spacer size={10} />
          <HorizontalRule />
          <Spacer size={10} />
        </>
      )
      : (
        <Spacer size={10} />
      )

  const renderIcon = () => (
    <DownFilledArrow
      width={10}
      height={6}
      fill={theme.colors.white}
    />
  )

  const customRenderFn = () => (
    <RoundedButton
      text="My Listing"
      background={theme.colors.blue4}
      color={theme.colors.white}
      hoverBackground={theme.colors.transparentBlue4Alpha80}
      padding="0 24px"
      renderRightIcon={renderIcon}
    />
  )

  const showDashboardBtn = !(
    router.route.includes('/listing/[id]/create')
    && listing?.listingStatus.name === E.ListingStatus.draft
  )

  const renderUser = () => (
    <ContentContainer inDropDown={inDropDown}>
      {showDashboardBtn && (
        <>
          <Link
            href="/listing/dashboard"
            prefetch={false}
            passHref
          >
            <Anchor>
              <ItemRow
                inDropDown={inDropDown}
                onClick={toggleModal}
              >
                <IconContainer>
                  <DashboardIcon
                    width={20}
                    height={18}
                    fill={theme.colors.yellow4}
                  />
                </IconContainer>
                <TitleText>Dashboard</TitleText>
              </ItemRow>
            </Anchor>
          </Link>
          {renderDivider()}
        </>
      )}
      <EditLink>
        <Anchor>
          <ItemRow
            inDropDown={inDropDown}
            onClick={toggleModal}
          >
            <IconContainer>
              <EditIcon
                width={20}
                height={18}
                fill={theme.colors.yellow4}
              />
            </IconContainer>
            <TitleText>Edit Listing</TitleText>
          </ItemRow>
        </Anchor>
      </EditLink>
      {renderDivider()}
      <Link
        href="/listing/create"
        prefetch={false}
        passHref
      >
        <Anchor>
          <ItemRow
            inDropDown={inDropDown}
            onClick={toggleModal}
          >
            <IconContainer>
              <PlusIcon
                width={20}
                height={18}
                fill={theme.colors.yellow4}
              />
            </IconContainer>
            <TitleText>New Listing</TitleText>
          </ItemRow>
        </Anchor>
      </Link>
    </ContentContainer>
  )

  if (!inDropDown) return renderUser()

  return (
    <DropDown
      marginCorrection={-18}
      disabled={disabled}
      customRenderFn={customRenderFn}
    >
      {renderUser()}
    </DropDown>
  )
}

export default MyListing
