import { useRouter } from 'next/router'
import { FunctionComponent, useContext, useEffect, useState } from 'react'
import styled, { ThemeContext } from 'styled-components'

import HomeListerLogo from 'design-system/Logo/HomeListerLogo'

import Anchor from 'components/GlobalStyles/Anchor'
import RoundedButton from 'components/RoundedButton'
import Spacer from 'components/Spacer'

import { identify } from 'services/analytics'
import { EventHandlerContext, MenuModalContext, QueryParamsContext } from 'services/contexts'
import { useWindowSize } from 'services/hooks'
import { useUser } from 'services/swr/useUser'

import MobileMenu from './MobileMenu'
import MyAccount from './MyAccount'
import MyListing from './MyListing'
import NavLinks from './NavLinks'
import * as T from './types'

const Y_SCROLL_TRANSPARENCY_THRESHOLD = 50

interface INavbarContainerProps {
  isInListingFlow: boolean
  transparent?: boolean
  bottomBorder?: boolean
}

const NavHeaderContainer = styled.div<T.IModalOpenProps & INavbarContainerProps>`
  position: fixed;
  top: 0;
  display: flex;
  justify-content: center;
  width: 100%;
  margin: 0 auto;
  height: ${props => props.theme.metrics.headerHeightPhone}px;
  background-color: ${props => (props.transparent ? 'transparent' : props.theme.colors.white)};
  z-index: ${props => props.theme.zIndices.level10};
  transition: background-color 200ms ease-in;
  ${props => props.bottomBorder && `border-bottom: 1px solid ${props.theme.colors.neutral100};`};

  @media (orientation: portrait) {
    ${props =>
    props.modalOpen
      && `
      height: 100vh;
      overflow: hidden;
    `}
  }

  @media (min-width: ${props => props.theme.metrics.tablet}px) {
    height: ${props => props.theme.metrics.headerHeight}px;
    overflow: visible;
  }

  @media (min-width: ${({ theme: { metrics } }) => metrics.tablet + metrics.sidebarWidth}px) {
    overflow: visible;
    ${props =>
    props.isInListingFlow
      && `
      max-width: ${props.theme.metrics.listingFlowPageMaxWidth}px;
      left: 50%;
      transform: translateX(-50%);
    `}
  }
`

const NavHeaderContentContainer = styled.div<T.IModalOpenProps>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin: 0 24px;
  height: ${props => props.theme.metrics.headerHeightPhone}px;

  @media (min-width: ${props => props.theme.metrics.tablet}px) {
    height: 100%;
    margin: 0 42px;
  }

  @media (min-width: ${props => props.theme.metrics.desktop}px) {
    max-width: ${props => props.theme.metrics.contentMaxWidth}px;
  }
`

interface IIsLoggedInProp {
  isLoggedIn: boolean
}
const LogoContainer = styled.div<IIsLoggedInProp>`
  display: flex;
  padding-top: 5px;

  @media (min-width: ${props => props.theme.metrics.tablet}px) {
    padding-top: 0;
  }
`

const NavLinksContainer = styled.div`
  display: flex;
  justify-content: space-around;
`

const UserMenuContainer = styled.div`
  display: none;
  @media (min-width: ${props => props.theme.metrics.desktop}px) {
    display: flex;
  }
`

interface IProps {
  enableTransparency?: boolean
}
const NavigationHeader: FunctionComponent<IProps> = ({ enableTransparency }) => {
  // theme
  const theme = useContext(ThemeContext)
  const { width } = useWindowSize()
  const isMobile = width && width < theme.metrics.desktop

  // scroll position
  const [transparent, setTransparent] = useState(enableTransparency)

  const handleScroll = () => {
    const position = window.pageYOffset
    setTransparent(position < Y_SCROLL_TRANSPARENCY_THRESHOLD)
  }

  // disable links when a debounced input hasn't made its update API call yet
  const { debouncing } = useContext(EventHandlerContext)

  // routing
  const router = useRouter()

  const isInListingFlow
    = router.pathname.includes('/listing/') && router.pathname.includes('/create/')

  const isHomesForSalePage = router.pathname.split('/').pop() === 'homes-for-sale'

  const [pageChanged, setPageChanged] = useState(false)
  const navToSignin = () => router.push('/signin')

  // marketing campaign page route
  const [marketingCampaign, setMarketingCampaign] = useState(router.pathname === '/c/[campaign]')

  useEffect(() => {
    if (enableTransparency) {
      window.addEventListener('scroll', handleScroll, { passive: true })

      return () => {
        window.removeEventListener('scroll', handleScroll)
      }
    }
  }, [router.pathname])

  useEffect(() => {
    setPageChanged(true)
    setMarketingCampaign(router.pathname === '/c/[campaign]')
    setTransparent(enableTransparency)
  }, [router.pathname])

  // query for appending UTM params
  const { params: query, setParams } = useContext(QueryParamsContext)

  const { modalOpen, openModal, closeModal } = useContext(MenuModalContext)

  const toggleModal = () => {
    if (!modalOpen) openModal()
    else closeModal()
  }

  // user data
  const { user, isValidating, logout } = useUser()
  const [initialPageLoad, setInitialPageLoad] = useState(true)
  const [loadingUser, setLoadingUser] = useState(true)

  useEffect(() => {
    if (initialPageLoad) return setInitialPageLoad(false)

    if (isValidating) return

    setLoadingUser(false)
  }, [user, initialPageLoad, isValidating])

  // identify user with Segment on every new page they route to
  // note: this covers Segment's requirement to identify user on signup and login
  useEffect(() => {
    if (pageChanged && user) {
      identify(user._id, {
        userId: user._id,
        email: user.email,
        name: user.name,
      })
      setPageChanged(false)
    }
  }, [pageChanged, user])

  const handleLogout = async () => {
    await router.push('/') // needs to be awaited or else hooks will pick up on no `user` too soon
    logout()
    setParams({})
    closeModal()
  }

  return (
    <NavHeaderContainer
      modalOpen={modalOpen}
      isInListingFlow={isInListingFlow}
      transparent={transparent}
      bottomBorder={isHomesForSalePage}
    >
      <NavHeaderContentContainer modalOpen={modalOpen}>
        <LogoContainer isLoggedIn={!!user}>
          {/* NB: Clicking this pseudo-disabled link while debouncing will cause a next/link error
                  to appear in the console, but it is harmless on production.
              */}
          {/* NB: don't use a Link here, prefetch can mess with homepage A/B redirect tests */}
          <Anchor
            href={debouncing ? '' : '/'}
            aria-label="Go to homepage"
          >
            <HomeListerLogo
              waiting={debouncing}
              transparent={transparent}
            />
          </Anchor>
        </LogoContainer>
        <NavLinksContainer>
          {!loadingUser && !isInListingFlow && <NavLinks transparent={transparent} />}
        </NavLinksContainer>
        <UserMenuContainer>
          {!user && !loadingUser && (
            <>
              <RoundedButton
                background={theme.colors.transparent}
                color={transparent ? theme.colors.white : theme.colors.blue500}
                borderColor={transparent ? theme.colors.white : theme.colors.blue500}
                hoverBackground={transparent ? theme.colors.white : theme.colors.transparent}
                hoverBorderColor={transparent ? theme.colors.white : theme.colors.blue400}
                hoverColor={theme.colors.blue400}
                borderWidth={1}
                padding="0 24px"
                text="Log In"
                onClick={navToSignin}
              />
              <Spacer size={18} />
              <RoundedButton
                background={transparent ? theme.colors.white : theme.colors.blue500}
                color={transparent ? theme.colors.neutral900 : theme.colors.white}
                hoverBackground={transparent ? theme.colors.blue500 : theme.colors.blue400}
                hoverColor={transparent ? theme.colors.white : theme.colors.white}
                boxShadow={theme.shadows.blue.level1}
                padding="0 24px"
                text={marketingCampaign ? 'Sign Up' : 'Get Started'}
                href={{
                  pathname: '/listing/create',
                  query: query as { [key: string]: string },
                }}
              />
            </>
          )}
          {user && !isInListingFlow && (
            <>
              <MyListing
                inDropDown
                disabled={debouncing}
              />
              <Spacer size={18} />
              <MyAccount
                inDropDown
                disabled={debouncing}
                logout={handleLogout}
                transparent={transparent}
              />
            </>
          )}
        </UserMenuContainer>
      </NavHeaderContentContainer>
      {(isInListingFlow || isMobile) && (
        <MobileMenu
          user={user}
          logout={handleLogout}
          modalOpen={modalOpen}
          toggleModal={toggleModal}
          transparent={transparent}
        />
      )}
    </NavHeaderContainer>
  )
}

export default NavigationHeader
