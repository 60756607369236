import Cookies from 'js-cookie'
import useSWR, { mutate, useSWRConfig } from 'swr'

import { fetchWithToken } from 'services/fetchers'
import { shutdownIntercom } from 'services/intercom'

import * as T from 'types'

import { api } from '../api'
import getErrorMessage from './utils/getErrorMessage'
import isServerError from './utils/isServerError'

export const useUser = () => {
  const { mutate: mutateAll } = useSWRConfig()
  const response = useSWR<T.IUser | T.IServerError>('/users/me', fetchWithToken)

  let user: T.IUser | undefined
  if (response.data && !isServerError(response.data)) user = response.data

  const error = getErrorMessage(response, 'user')

  const logout = () => {
    // remove cookies that are associated with a particular user
    Cookies.remove('token')
    Cookies.remove('token', { secure: true, sameSite: 'none', domain: '.homelister.com' })
    Cookies.remove('vwoUserId')
    Cookies.remove('vwoVariations')

    // remove the token from the authorization header we use to authenticate requests on the BE server
    api.api.setHeader('Authorization', '')

    // reset the cache of SWR - so all paths *should* have null values/be purged (note: seems flakey)
    mutateAll(() => true, undefined, { revalidate: false })
    // reset the user swr route to null just to be safe
    mutate('/users/me', null)

    // tell Intercom the user is no longer logged in
    shutdownIntercom()
  }

  return { ...response, user, error, logout }
}
